import { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '@/store';

export const useCatchAsyncError = () => {
  const error = useAppSelector((state) => state.common.error);
  const throwError = useAsyncError();

  useEffect(() => {
    if (error) {
      throwError(error);
    }
  }, [error]);
};

export const useAsyncError = () => {
  const [, setError] = useState();
  return useCallback(
    (error: Error) => {
      setTimeout(() => {
        setError(() => {
          throw error;
        });
      }, 0);
    },
    [setError],
  );
};
