import React, { type ReactHTML } from 'react';

import type { TUIResourceId, TUIResourceValueMap } from '@/ducks/common/resources';

import HtmlContent from '@/components/elements/HtmlContent';
import { useUIResource } from '@/ducks/common/resources';

type TProps = {
  className?: string;
  id: TUIResourceId;
  node?: keyof ReactHTML;
  values?: TUIResourceValueMap;
};

const UIResourceHtml = ({ className, id, node, values }: TProps) => {
  const content = useUIResource(id, values);
  return <HtmlContent className={className} content={content} node={node} />;
};

export default UIResourceHtml;
