export type TSailingDatType = 'debark' | 'embark' | 'port' | 'sailing';

export enum ClassificationCode {
  Ausnzitineraries = 'AUSNZITINERARIES',
  The2024Itin = '2024ITIN',
  Weekend = 'WEEKEND',
  AlmostSoldOut = 'ALMOSTSOLDOUT',
}

export enum RegionCutted {
  Caribb = 'CARIBB..',
  Europe = 'EUROPE',
  Medite = 'MEDITE..',
  South = 'SOUTH ..',
  Transa = 'TRANSA..',
}
