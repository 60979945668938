import React, { type ReactNode } from 'react';

import cn from 'classnames';

import type { TBoxSide, TOptional, TSideAlign } from '@/types/common';

import './Balloon.scss';

type TProps = {
  beakAlign?: TOptional<TSideAlign>;
  beakSide?: TOptional<TBoxSide>;
  children?: ReactNode;
  className?: string;
};

const Balloon = ({ beakAlign = 'center', beakSide = 'bottom', children, className }: TProps) => (
  <div className={cn('Balloon', className, `_${beakSide}`, `_${beakAlign}`)}>{children}</div>
);

export default Balloon;
