import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Flyout from '@/components/Flyout';
import MarqueeBanner from '@/components/MarqueeBanner';
import Button from '@/components/elements/Button';
import { ModalContent, ModalContentBody } from '@/components/elements/ModalContent';
import {
  ACCESS_KEY_SOURCE,
  ACCESS_KEY_STATES,
  getAccessKey,
  getIsAccessKeyStateValid,
  removeActiveAccessKeyAction,
} from '@/ducks/accessKeys';
import { resetAKFlowFully } from '@/ducks/accessKeys/utils';
import { useUIResourcePool } from '@/ducks/common/resources';
import { selectSettings } from '@/ducks/common/settings/selectors';
import { FormattedMessage, useFormattedMessageContext } from '@/helpers/formatted-message';
import { getPromotionTitle } from '@/helpers/util';
import useBodyClassModifier from '@/hooks/useBodyClassModifier';
import { useAppDispatch } from '@/store';
import tagmanager from '@/tagmanager';
import { createTracker } from '@/tagmanager/tracker';

import { AccessKeyFlyout } from './Flyout';

import './AccessKeyCard.scss';

const MARQUEE_UI_RESOURCE_POOL = {
  description: 'AccessKey.bannerMessage',
  desktopClearText: 'AccessKey.clearCTA',
  mobileClearText: 'AccessKey.clearCTA.short',
  promoIcon: 'AccessKey.emoji.key',
  promoText: 'AccessKey.bookShareToast',
  title: 'AccessKey.bannerTitle',
};

const trackEvent = createTracker({ action: 'link-click' });

const AccessKeyCardErrorFlyout = ({ clearAccessKey }) => (
  <ModalContent>
    <ModalContentBody>
      <h1>
        <FormattedMessage defaultMessage="Oops!" id="VoyagePlanner.ItineraryResults.accessKeyInvalidPopup.title" />
      </h1>
      <p>
        <FormattedMessage
          defaultMessage="Your access key is no longer valid. You can continue booking without the access key or cancel and go back to the home page."
          id="VoyagePlanner.ItineraryResults.accessKeyInvalidPopup.body"
        />
      </p>
      <div className="accessKeyInvalidPopup__actionButtons">
        <Button
          onClick={() => {
            window.location.href = '/';
          }}
          variant="secondary"
        >
          <FormattedMessage defaultMessage="Cancel" id="VoyagePlanner.ItineraryResults.accessKeyInvalidPopup.cancel" />
        </Button>
        <Button onClick={clearAccessKey} variant="secondary">
          <FormattedMessage
            defaultMessage="Continue"
            id="VoyagePlanner.ItineraryResults.accessKeyInvalidPopup.continue"
          />
        </Button>
      </div>
    </ModalContentBody>
  </ModalContent>
);

AccessKeyCardErrorFlyout.propTypes = {
  clearAccessKey: PropTypes.func.isRequired,
};

const CLEAR_KEY_TEXT_CMS_OPTION = {
  defaultMessage: 'Clear access key',
  id: 'AccessKey.clearCTA',
};

const AccessKeyCard = ({ accessKeyDetails, actions, config: { promotionTitle } }) => {
  const dispatch = useAppDispatch();
  const { accessKey, error, nodeType = '', source, state, title = '' } = accessKeyDetails;
  const [isAccessCardFlyoutOpened, setIsAccessCardFlyoutOpened] = useState(false);

  const { formatMessage } = useFormattedMessageContext();
  useBodyClassModifier('-with-access-key', accessKey);

  // Access key was initially invalid
  useEffect(() => {
    if (!!error && accessKey == null) {
      actions.removeActiveAccessKey();
    }
  }, [error, accessKey, actions]);

  const openAccessCardFlyout = useCallback(() => {
    const labelParts = ['tell-me-more'];
    if (title) {
      labelParts.push(title);
    }
    labelParts.push(getPromotionTitle(nodeType, promotionTitle));
    trackEvent({ label: labelParts.join('|') });
    setIsAccessCardFlyoutOpened(true);
  }, [setIsAccessCardFlyoutOpened, promotionTitle, nodeType, title]);

  const closeAccessCardFlyout = useCallback(() => setIsAccessCardFlyoutOpened(false), [setIsAccessCardFlyoutOpened]);

  const clearAccessKey = useCallback(() => {
    const buttonText = formatMessage(CLEAR_KEY_TEXT_CMS_OPTION)?.[0];
    tagmanager.tracker.common.trackIconClick({
      labels: [buttonText],
    });

    dispatch(resetAKFlowFully());
  }, [formatMessage, dispatch]);

  const MarqueeMessages = useUIResourcePool(MARQUEE_UI_RESOURCE_POOL);

  if (state === ACCESS_KEY_STATES.INVALID && source === ACCESS_KEY_SOURCE.URL) {
    return (
      <Flyout
        className="accessKeyInvalidPopup -adaptive-height"
        direction="modal"
        hideCrossButton
        onDismiss={() => {}}
        open
      >
        <AccessKeyCardErrorFlyout clearAccessKey={clearAccessKey} />
      </Flyout>
    );
  }

  if (!getIsAccessKeyStateValid(state)) {
    return null;
  }

  return (
    <>
      <MarqueeBanner
        description={accessKeyDetails.shortDescription ?? MarqueeMessages.description}
        desktopClearText={MarqueeMessages.desktopClearText}
        mobileClearText={MarqueeMessages.mobileClearText}
        onClearClick={clearAccessKey}
        onClick={openAccessCardFlyout}
        promoIcon={MarqueeMessages.promoIcon}
        promoText={accessKeyDetails.promoBenefit ?? MarqueeMessages.promoText}
        showIcon
        title={accessKeyDetails.title ?? MarqueeMessages.title}
      />

      {isAccessCardFlyoutOpened && (
        <Flyout
          className="AccessKeyFlyoutWrapper"
          direction="right"
          onDismiss={closeAccessCardFlyout}
          open={isAccessCardFlyoutOpened}
        >
          <AccessKeyFlyout accessCardFlyoutData={accessKeyDetails} />
        </Flyout>
      )}
    </>
  );
};

AccessKeyCard.propTypes = {
  accessKeyDetails: PropTypes.shape({
    accessKey: PropTypes.string,
    error: PropTypes.string,
    nodeType: PropTypes.string,
    promoBenefit: PropTypes.string,
    shortDescription: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    removeActiveAccessKey: PropTypes.func,
  }).isRequired,
  config: PropTypes.shape({
    promotionTitle: PropTypes.shape({}),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  accessKeyDetails: getAccessKey(state),
  config: selectSettings(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        removeActiveAccessKey: removeActiveAccessKeyAction,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessKeyCard);
