import type { TPortMessage } from '@/types';

import UIResource from '@/components/UIResource';

type Props = {
  values: TPortMessage;
};

const PrecheckoutPortMessage = ({ values }: Props) => {
  const newPort = <span className="highlightedPort">{values.swappedToPort?.name}</span>;
  const previousPort = <span className="strike-trough">{values.swappedFromPort?.name}</span>;
  return (
    <>
      <span className="portHeading">
        <UIResource id="Itinerary flyout – Note to sailors heading" />
      </span>
      <span className="body">
        <UIResource
          id="Port change message with strikethrough port"
          values={{
            'New Port': newPort,
            'Strikethrough Previous Port': previousPort,
          }}
        />
      </span>
    </>
  );
};

export default PrecheckoutPortMessage;
