import { gql } from '@apollo/client';

export const FETCH_CLIENT_INFO = gql`
  query CLIENT_INFO {
    lookup {
      currenciesData {
        currencies {
          code
          flagIcon
          label
          symbol
        }
        defaultCountryCode
        defaultCurrencyCode
        ipCountryCode
        ipStateCode
      }
    }
  }
`;

export type GQLClientInfo = {
  currencies: {
    code: string;
    flagIcon: string;
    label: string;
    symbol: string;
  }[];
  defaultCountryCode: string;
  defaultCurrencyCode: string;
  ipCountryCode: string;
  ipStateCode: string;
};
export type GQLClientInfoResponse = {
  lookup: {
    currenciesData: GQLClientInfo;
  };
};
