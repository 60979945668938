import { useEffect, useState } from 'react';

import { getVWOValue } from '@/helpers/vwo/getVWOValue';
import getVWOClientInstance from '@/helpers/vwo/instance';
import { VWOFlag } from '@/types/vwo';

export { VWOFlag };

type TResult = {
  isVWOLoading: boolean;
  value: boolean;
};

const useVWOFeature = (featureKey: VWOFlag): TResult => {
  const [isVWOLoading, setIsVWOLoading] = useState(true);
  const [value, setValue] = useState(false);

  useEffect(() => {
    getVWOClientInstance()
      .then((vwoClient) => {
        const value = getVWOValue({ key: featureKey, vwoClient });
        setValue(value);
      })
      .catch((error) => {
        console.error('VWO client error:', error);
        setValue(getVWOValue({ key: featureKey }));
      })
      .finally(() => {
        setIsVWOLoading(false);
      });
  }, [featureKey]);

  return { isVWOLoading, value };
};

export const useVWOFlag = (key: VWOFlag): boolean => useVWOFeature(key).value;

export default useVWOFeature;
