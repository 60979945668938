import { isServerSide } from '@/helpers/isServerSide';

import useClassModifier from './useClassModifier';

const useBodyClassModifier = (cssClassModifier, isSet) => {
  useClassModifier({
    cssClassModifier,
    element: !isServerSide() ? document.body : null,
    isSet,
  });
};

export default useBodyClassModifier;
