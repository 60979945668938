import classNames from 'classnames';

import type { TUIResourceId } from '@/ducks/common/resources';

import UIResourceHtml from '@/components/UIResource/Html';
import { ClassificationCode } from '@/infra/types/voyageInfo/base';

import classes from './AlmostSoldOut.module.scss';

type TProps = {
  className?: string;
  classificationCodes: ClassificationCode[];
  messageId: TUIResourceId;
  withoutStyles?: boolean;
};

const AlmostSoldOut = ({ className, classificationCodes = [], messageId, withoutStyles = false }: TProps) => {
  const isAlmostSoldOut = classificationCodes.includes(ClassificationCode.AlmostSoldOut);

  if (!isAlmostSoldOut) return null;

  return (
    <span className={classNames({ [classes.almostSoldOut as string]: !withoutStyles }, className)}>
      <UIResourceHtml id={messageId} node="span" />
    </span>
  );
};

export default AlmostSoldOut;
