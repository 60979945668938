import { gql } from '@apollo/client';

export const FETCH_AGENCY_CURRENCY_DETAILS = gql`
  query FetchAgencyCurrencyDetails(
    $agencyId: String
    $agentId: String
    $bookingChannel: String
    $currency: String!
    $source: String!
  ) {
    fetchAgencyCurrencyDetails(
      value: {
        agencyId: $agencyId
        agentId: $agentId
        bookingChannel: $bookingChannel
        currency: $currency
        source: $source
      }
    ) {
      defaultCurrency
      additionalCurrencies {
        code
        label
        flagIcon
        symbol
      }
      valid
      bookingChannel
      agent {
        agentId
        agentName
        email
        agentAddress {
          street
          city
          state
          country
        }
        agentPhone {
          code
          phoneNumber
        }
      }
      agency {
        agencyName
        agencyId
        agencyAddress {
          street
          city
          state
          country
        }
        agencyPhone {
          code
          phoneNumber
        }
      }
    }
  }
`;
