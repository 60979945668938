import PropTypes from 'prop-types';
import React from 'react';

import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/Summary';
import { FiltersPriceType } from '@/infra/types/common/filters';

import { Price } from '../elements';

const CabinPrice = ({
  abTestPriceType,
  cabins = 1,
  currencyCode,
  descriptionPrefix = '',
  duration,
  idForAriaLabelSafari,
  isForceLineBreak,
  isFromAdvFilter,
  isVariantBAndAmount,
  isVariantBAndDiscount,
  sailors,
  startingPrice,
  strikethroughValue,
}) => {
  const priceType =
    abTestPriceType === FiltersPriceType.sailorPerNight && isFromAdvFilter
      ? FiltersPriceType.perCabin
      : abTestPriceType;
  const price =
    abTestPriceType === FiltersPriceType.sailorPerNight && !isFromAdvFilter && !isVariantBAndDiscount
      ? getAmountPerVoyage(startingPrice, duration, { sailors })
      : getAmountPerItem(startingPrice, { cabins, priceType, sailors });
  let originalAmount =
    abTestPriceType === FiltersPriceType.sailorPerNight && !isFromAdvFilter && !isVariantBAndDiscount
      ? getAmountPerVoyage(startingPrice, duration, { sailors }, 'originalAmount')
      : getAmountPerItem(startingPrice, { cabins, priceType, sailors }, 'originalAmount');

  if (strikethroughValue) {
    originalAmount += strikethroughValue;
  }

  return (
    <Price
      amount={abTestPriceType === FiltersPriceType.sailorPerNight ? Math.ceil(price) : price}
      currencyCode={currencyCode}
      descriptionPrefix={descriptionPrefix}
      idForAriaLabelSafari={idForAriaLabelSafari}
      isForceLineBreak={isForceLineBreak}
      isVariantBAndAmount={isVariantBAndAmount}
      isVariantBAndDiscount={isVariantBAndDiscount}
      originalAmount={abTestPriceType === FiltersPriceType.sailorPerNight ? Math.ceil(originalAmount) : originalAmount}
    />
  );
};

CabinPrice.propTypes = {
  abTestPriceType: PropTypes.string,
  cabins: PropTypes.number,
  currencyCode: PropTypes.string,
  isForceLineBreak: PropTypes.bool,
  isFromAdvFilter: PropTypes.bool,
  isVariantBAndAmount: PropTypes.bool,
  isVariantBAndDiscount: PropTypes.bool,
  sailors: PropTypes.number,
  startingPrice: PropTypes.shape({}),
};

CabinPrice.defaultProps = {
  abTestPriceType: '',
  cabins: 1,
  currencyCode: 'USD',
  isForceLineBreak: true,
  isFromAdvFilter: false,
  isVariantBAndAmount: false,
  isVariantBAndDiscount: false,
  sailors: null,
  startingPrice: {},
};
export default CabinPrice;
