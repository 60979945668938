import { useCallback, useMemo } from 'react';

const useWrapForFrenchWords = () => {
  return useCallback((text) => {
    const frenchRegex = /\b[a-zA-Z.-]*[àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ][a-zA-Z.-]*\b/g;
    return text.replace(frenchRegex, (match) => `<span lang="fr">${match}</span>`);
  }, []);
};

export const useFrenchWordsWrapper = (text = ' ') => {
  const wrapFrenchWords = useWrapForFrenchWords();

  return useMemo(() => wrapFrenchWords(text), [text, wrapFrenchWords]);
};
