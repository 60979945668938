import { useEffect } from 'react';

const useClassModifier = ({ cssClassModifier, element, isSet }) => {
  useEffect(() => {
    if (element === 'body') {
      document.body?.classList?.toggle(cssClassModifier, !!isSet);
    } else {
      element?.classList?.toggle(cssClassModifier, !!isSet);
    }
    return () => element?.classList?.remove(cssClassModifier);
  }, [isSet]);
};

export default useClassModifier;
