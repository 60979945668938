import Script from 'next/script';
import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import type { UnknownObject } from '@/types/utils';

import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { selectPrimarySailorWithInsurance } from '@/ducks/travelParty/selectors';
import { getPaymentBooking } from '@/helpers/managers/payment';
import { getSessionStorageValue } from '@/helpers/util';
import { useAppSelector } from '@/store';

const sideqikScript =
  '!function(w,d,s,u,n,t,p){w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},t=d.createElement(s),p=d.getElementsByTagName(s)[0],t.id="sideqik-sdk",t.async=1,t.src=u,p.parentNode.insertBefore(t,p)}(window,document,"script","https://d1hrk5gt3yn7pi.cloudfront.net/api/sideqik-api-1.4.js#5b9043336d8cc10021000759","sideqik");';

const getScriptWithPrimarySailorRevenue = ({
  currency,
  email,
  revenue,
}: Record<'currency' | 'email' | 'revenue', string>) =>
  `!function(w,d,s,u,n,t,p){w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},t=d.createElement(s),p=d.getElementsByTagName(s)[0],t.id='sideqik-sdk',t.async=1,t.src=u,p.parentNode.insertBefore(t,p)}(window,document,"script","https://d1hrk5gt3yn7pi.cloudfront.net/api/sideqik-api-1.4.js#5b9043336d8cc10021000759","sideqik"),sideqik("track","purchase",{revenue:"${revenue}",email:"${email}",currency:"${currency}"});`;

const withSideqikScriptForBookingFlow = (WrappedComponent: React.ElementType, { usePrimarySailorRevenue = false }) => {
  const Wrapped = (props: UnknownObject) => {
    const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

    const currencyCode = useAppSelector(selectCurrencyCode);
    const reduxPrimarySailor = useAppSelector(selectPrimarySailorWithInsurance);
    const storagePrimarySailor = getSessionStorageValue('primarySailor');
    const primarySailor = isEmpty(reduxPrimarySailor) ? storagePrimarySailor : reduxPrimarySailor;

    const bookNowData = getPaymentBooking();

    useEffect(() => {
      if (!usePrimarySailorRevenue || isAllDataLoaded) return;

      if (!currencyCode) return;
      if (isEmpty(primarySailor)) return;
      if (isEmpty(bookNowData)) return;

      setIsAllDataLoaded(true);
    }, [isAllDataLoaded, currencyCode, primarySailor, bookNowData]);

    useEffect(() => {
      // nextjs not removing previous (general) scripts
      document.getElementById(`sideqikScript${usePrimarySailorRevenue ? 'General' : 'Personal'}Flow`)?.remove();
    }, [usePrimarySailorRevenue]);

    return (
      <>
        {usePrimarySailorRevenue ? (
          isAllDataLoaded && (
            <Script id="sideqikScriptPersonalFlow" strategy="lazyOnload">
              {getScriptWithPrimarySailorRevenue({
                currency: currencyCode,
                email: primarySailor.email,
                revenue: bookNowData.totalAmount,
              })}
            </Script>
          )
        ) : (
          <Script id="sideqikScriptGeneralFlow" strategy="lazyOnload">
            {sideqikScript}
          </Script>
        )}

        <WrappedComponent {...props} />
      </>
    );
  };
  return Wrapped;
};

export default withSideqikScriptForBookingFlow;
