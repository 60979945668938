import { useCallback } from 'react';

import type { TStrikethroughAddon } from '@/features/strikethrough/types';

import { selectCabins, selectPriceType, selectSailors } from '@/ducks/filters/selectors';
import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { selectStrikethroughData } from '@/features/strikethrough/selectors';
import { getAmountPerItemFlat, getAmountPerVoyageFlat } from '@/helpers/data/mappers/Summary';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

export const STRIKETHROUGH_EXTERNAL_ID = 'STRIKETHROUGH';

type GetSeawareOverrideParams = {
  cabins?: number;
  duration?: number;
  priceType?: FiltersPriceType;
  sailors?: number;
  value?: number;
};

const useStrikethrough = (classificationCodes?: string[]) => {
  const sailingData = useAppSelector(selectSailingData);
  const strikethroughData = useAppSelector(selectStrikethroughData);
  const priceType = useAppSelector(selectPriceType);
  const sailors = useAppSelector(selectSailors);
  const cabins = useAppSelector(selectCabins);
  const duration = sailingData?.duration;

  const calculateStrikethroughValue = (
    value: number,
    priceType: FiltersPriceType,
    duration: number,
    sailors: number,
    cabins: number,
  ) => {
    return priceType === FiltersPriceType.sailorPerNight
      ? getAmountPerVoyageFlat(value, duration, { sailors })
      : getAmountPerItemFlat(value, { cabins, priceType, sailors });
  };

  return useCallback(
    (cabinType?: string, overrides: GetSeawareOverrideParams = {}) => {
      if (!cabinType || !strikethroughData) return 0;

      const isEnabledForVoyage = (classificationCodes ?? sailingData?.classificationCodes)?.includes(
        STRIKETHROUGH_EXTERNAL_ID,
      );

      if (!strikethroughData.strikethroughEnabled || !isEnabledForVoyage) {
        return 0;
      }

      const addon = strikethroughData.strikeThroughAddons.find(
        (addon: TStrikethroughAddon) => addon.cabinCategory.seawareId === cabinType,
      );

      if (!addon) return 0;

      const {
        cabins: overrideCabins = cabins,
        duration: overrideDuration = duration ?? 0,
        priceType: overridePriceType = priceType,
        sailors: overrideSailors = sailors,
        value = Number(addon.strikeThroughValue),
      } = overrides;

      return calculateStrikethroughValue(value, overridePriceType, overrideDuration, overrideSailors, overrideCabins);
    },
    [strikethroughData, sailingData, ...(classificationCodes || []), priceType, duration, sailors, cabins],
  );
};

export default useStrikethrough;
