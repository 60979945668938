'use client';
import { useRouterTranslator } from '@/ducks/filters/hooks';
type BasePageProps = {
  children: React.ReactNode;
};
const BasePage = ({ children }: BasePageProps) => {
  useRouterTranslator();
  return children;
};
export default BasePage;
