import { selectAccessKey } from '@/ducks/accessKeys';
import {
  selectAccessible,
  selectCabinType,
  selectCurrencyCode,
  selectDurations,
  selectFromDate,
  selectHomePorts,
  selectMetaType,
  selectPriceType,
  selectSailors,
  selectSelectedItineraries,
  selectSelectedPortsOfCall,
  selectShipList,
  selectSortType,
  selectToDate,
  selectVoyageIds,
  selectWeekend,
} from '@/ducks/filters/selectors';
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect';
import { useAppDispatch, useAppSelector } from '@/store';

import { fetchPackages, filterPackages } from './chooseVoyage/actions/fetchPackages';

export const useFetchPackagesAndSailings = () => {
  const dispatch = useAppDispatch();

  // fetch
  const fromDate = useAppSelector(selectFromDate);
  const toDate = useAppSelector(selectToDate);
  const sailors = useAppSelector(selectSailors);
  const cabinType = useAppSelector(selectCabinType);
  const metaType = useAppSelector(selectMetaType);
  const voyageIds = useAppSelector(selectVoyageIds);
  const currencyCode = useAppSelector(selectCurrencyCode);

  // filter
  const priceType = useAppSelector(selectPriceType);
  const sortType = useAppSelector(selectSortType);
  const durations = useAppSelector(selectDurations);
  const itineraries = useAppSelector(selectSelectedItineraries);
  const ports = useAppSelector(selectSelectedPortsOfCall);
  const weekend = useAppSelector(selectWeekend);
  const accessible = useAppSelector(selectAccessible);
  const ships = useAppSelector(selectShipList);
  const homePorts = useAppSelector(selectHomePorts);
  const accessKey = useAppSelector(selectAccessKey);

  useDidUpdateEffect(() => {
    dispatch(fetchPackages());
  }, [dispatch, cabinType, metaType, voyageIds, currencyCode, sailors, fromDate, toDate, accessKey]);

  useDidUpdateEffect(() => {
    dispatch(filterPackages());
  }, [dispatch, priceType, sortType, durations, itineraries, ports, weekend, accessible, ships, homePorts]);
};
