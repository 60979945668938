import type { AppDispatch } from '@/store';

import { removeActiveAccessKeyAction } from '@/ducks/accessKeys/actions';
import { ACCESS_KEY_PARAM } from '@/ducks/accessKeys/constants';
import { getAllVoyagesFiltersKeys } from '@/ducks/filters/utils';
import { removeParticularSearchParams, removeSearchParamsExcept } from '@/ducks/routes/history';

export const resetAKFlowFully = () => async (dispatch: AppDispatch) => {
  await removeParticularSearchParams([ACCESS_KEY_PARAM]);
  dispatch(removeActiveAccessKeyAction(false));
};

export const resetAKFlowFullyAlongWithFilters = () => async (dispatch: AppDispatch) => {
  await removeParticularSearchParams(getAllVoyagesFiltersKeys([ACCESS_KEY_PARAM]));
  dispatch(removeActiveAccessKeyAction(false));
};

export const resetAKFlowSearchCriteria = () => (_dispatch: AppDispatch) => {
  removeSearchParamsExcept([ACCESS_KEY_PARAM]);
};
