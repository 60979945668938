import Link from 'next/link';
import React, { isValidElement, useCallback, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import Arrow from '@publicImages/svg/arrow-right.svg?icon';

import type { Port } from '@/infra/types/voyageInfo/port';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import AlmostSoldOut from '@/components/AlmostSoldOut';
import { BlackFridayPromoMessage } from '@/components/BlackFriday/PromoMessage';
import useIsPromoActive, {
  MerchandisingBannerComponents,
} from '@/components/PromoBanner/Merchandising/hooks/useIsPromoActive';
import UIResource from '@/components/UIResource';
import { Price } from '@/components/elements';
import * as Dialog from '@/components/elements/Dialog';
import { DesktopOnly, MobileOnly, Screen } from '@/components/elements/Helpers';
import { CABIN_CATEGORY_CODES } from '@/constants/cabinCategoryCodes';
import { useUIResourcePool } from '@/ducks/common/resources';
import { selectConfigServiceFeatureSpecialSailings } from '@/ducks/common/settings/selectors';
import { useGetSpecialSailings } from '@/ducks/lookup/hooks/useGetSpecialSailings';
import { findSwappedPort } from '@/ducks/pages/chooseVoyage/groupPackagesWithSameNames';
import useStrikethrough from '@/ducks/programs/strikethrough/useStrikethrough';
import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/Summary';
import { formatDate } from '@/helpers/util/dateUtil';
import { getSpecialSailingName } from '@/helpers/util/misc';
import { useIsMobile } from '@/hooks/useIsDevice';
import useOnKeyDown from '@/hooks/useOnKeyDown';
import usePrecheckoutUrlWithParams from '@/hooks/usePrecheckoutPath';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import { SpecialSailingModal } from '../SpecialSailingModal';

import classes from './PricedSailingCard.module.scss';

type Props = {
  cabins: number;
  className?: string;
  defaultPackagePorts: Port[];
  isPortChangeAvailable: boolean;
  onClick?: (sailing: SailingFromPackages) => void;
  priceType: FiltersPriceType;
  sailing: SailingFromPackages;
  sailors: number;
};

export const PricedSailingCard = ({
  cabins = 1,
  className,
  defaultPackagePorts,
  isPortChangeAvailable,
  onClick,
  priceType,
  sailing,
  sailors = 2,
}: Props) => {
  const { classificationCodes, duration, endDate, id, packageCode, ports, startDate, startingPrice } = sailing;
  const specialSailingButtonRef = useRef<HTMLDivElement>(null);
  const specialSailings = useGetSpecialSailings();
  const hasSpecialSailingFeature = useAppSelector(selectConfigServiceFeatureSpecialSailings);
  const [specialSailingOpened, setSpecialSailingOpened] = useState(false);

  const price = useMemo(
    () =>
      priceType === FiltersPriceType.sailorPerNight
        ? Math.ceil(getAmountPerVoyage(startingPrice, duration, { sailors }))
        : getAmountPerItem(startingPrice, { cabins, priceType, sailors }),
    [cabins, duration, priceType, sailors, startingPrice],
  );
  const specialSailingName = useMemo(
    () => getSpecialSailingName(classificationCodes, specialSailings),
    [classificationCodes, specialSailings],
  );

  const isSpecialSailing = !!specialSailingName && hasSpecialSailingFeature;

  const { currencyCode } = startingPrice || {};

  const getStrikethroughForPackage = useStrikethrough(classificationCodes);
  const strikethroughValue = getStrikethroughForPackage(startingPrice?.minPricedCategoryCode);

  let originalAmount;
  if (strikethroughValue) {
    originalAmount = price + strikethroughValue;
  }

  const isPromoEnabled = useIsPromoActive(sailing, MerchandisingBannerComponents.sailingItem);
  const shouldColorOriginalPrice = isPromoEnabled && !strikethroughValue;

  const swappedPort = useMemo(
    () => isPortChangeAvailable && findSwappedPort(defaultPackagePorts || [], ports),
    [defaultPackagePorts, isPortChangeAvailable, ports],
  );
  const isPortSwap = isValidElement(swappedPort);

  const urlPath = usePrecheckoutUrlWithParams(sailing);

  const onClickHandler = useCallback(() => {
    onClick?.(sailing);
  }, [onClick, sailing]);

  const onSpecialSailingOpenHandler = useCallback(
    (e: React.UIEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setSpecialSailingOpened(true);
    },
    [setSpecialSailingOpened],
  );

  const onSpecialSailingKeyDownHandler = useOnKeyDown(onSpecialSailingOpenHandler);

  const returnFocusToButton = useCallback((e: Event) => {
    e.preventDefault();
    specialSailingButtonRef.current?.focus();
  }, []);

  const leadCabin = sailing?.cabinCategoryCode;
  const isSuite = [CABIN_CATEGORY_CODES.MEGA_ROCKSTAR, CABIN_CATEGORY_CODES.ROCKSTAR_SUITES].includes(leadCabin);
  const { desktopText, mobileText } = useUIResourcePool(UI_RESOURCE_POOL);
  const isMobile = useIsMobile();
  const label = isMobile ? `${Screen.MOBILE}_${id}` : `${Screen.DESKTOP}_${id}`;

  return (
    <div className={classes.root}>
      <Link
        aria-labelledby={id}
        className={cn(classes.pricedSailingCard, className, {
          [classes.blackFridaySailingCard]: isPromoEnabled,
          [classes.isSpecialSailing]: isSpecialSailing,
          [classes.portSwap]: isPortSwap,
        })}
        data-id={id}
        data-packageid={packageCode}
        href={urlPath}
        onClick={onClickHandler}
        tabIndex={0}
        {...(isSuite ? { 'aria-describedby': label } : '')}
      >
        <div>
          <div className={classes.dates} id={id}>
            <AlmostSoldOut
              className={classes.almostSoldOut}
              classificationCodes={sailing.classificationCodes}
              messageId="AlmostSoldOut.SailingLevel.Emoji"
              withoutStyles
            />
            {formatDate(startDate, 'E, MMM dd')}
            {' - '}
            {formatDate(endDate, 'E, MMM dd')}

            <AlmostSoldOut
              className="sr-only"
              classificationCodes={sailing.classificationCodes}
              messageId="AlmostSoldOut.lastRemainingCabins"
              withoutStyles
            />
          </div>
          {isSpecialSailing && (
            <div
              aria-hidden="true"
              className={classes.specialSailingModalTrigger}
              onClick={onSpecialSailingOpenHandler}
              tabIndex={-1}
            >
              {specialSailingName}
            </div>
          )}

          <AlmostSoldOut
            className={classes.almostSoldOutMessage}
            classificationCodes={sailing.classificationCodes}
            messageId="AlmostSoldOut.lastRemainingCabins"
            withoutStyles
          />

          {isSuite && (
            <div className={classes.leadCabinText}>
              <DesktopOnly id={`${Screen.DESKTOP}_${id}`}>{desktopText}</DesktopOnly>
              <MobileOnly id={`${Screen.MOBILE}_${id}`}>{mobileText}</MobileOnly>
            </div>
          )}
        </div>

        <div>
          <div className={classes.priceAndArrow}>
            <span className={classes.price}>
              <span className={classes.priceFromLabel}>
                <UIResource id="Cabins.Lead.Price.label.from" />{' '}
              </span>
              <span
                className={cn(classes.price, {
                  [classes.blackFridayPrice]: shouldColorOriginalPrice,
                })}
              >
                <Price amount={price} currencyCode={currencyCode} originalAmount={originalAmount} />
              </span>
            </span>
            <Arrow />
          </div>
          {isPromoEnabled && <BlackFridayPromoMessage className={classes.promoDiscount} />}
        </div>
        {isPortSwap && swappedPort}
      </Link>

      {isSpecialSailing && (
        <Dialog.Root onOpenChange={setSpecialSailingOpened} open={specialSailingOpened}>
          <Dialog.Content
            className={classes.specialSailingModal}
            onCloseAutoFocus={returnFocusToButton}
            role="document"
            title={specialSailingName}
          >
            <SpecialSailingModal classificationCode={classificationCodes?.[0]} />
          </Dialog.Content>
        </Dialog.Root>
      )}

      {isSpecialSailing && (
        <div
          className={classes.specialSailingModalTriggerSrOnly}
          onClick={onSpecialSailingOpenHandler}
          onKeyDown={onSpecialSailingKeyDownHandler}
          ref={specialSailingButtonRef}
          tabIndex={0}
        >
          <span className="sr-only">{specialSailingName} more information</span>
        </div>
      )}
    </div>
  );
};

export const UI_RESOURCE_POOL = {
  desktopText: 'VoyageCard.onlySuitesRemaining',
  mobileText: 'VoyageCard.onlySuitesLeft',
};
