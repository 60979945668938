import { useSearchParams } from 'next/navigation';

export type withSearchParamsProps = {
  searchParams: ReturnType<typeof useSearchParams>;
};

const withSearchParams = <TProps,>(WrappedComponent: React.JSXElementConstructor<TProps & withSearchParamsProps>) =>
  function SearchParamsWrapper(props: TProps) {
    const searchParams = useSearchParams();
    return <WrappedComponent {...props} searchParams={searchParams} />;
  };

export default withSearchParams;
