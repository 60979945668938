import { gql } from '@apollo/client';

export const VALIDATE_CLIENT_ID_FOR_DNS = gql`
  query ($clientId: String) {
    dnsData(clientId: $clientId) {
      clientId
      dns
    }
  }
`;
