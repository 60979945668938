import cn from 'classnames';

import UIResource from '@/components/UIResource';

import classes from './PromoMessage.module.scss';

export const BlackFridayPromoMessage = ({ className }: { className?: string }) => {
  return (
    <div className={cn(classes.promoDiscount, className)}>
      <UIResource id="Promotion.sailingList.lineItem" />
    </div>
  );
};
