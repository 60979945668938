import { gql } from '@apollo/client';

import type { TNullable } from '@/infra/types/common';

export const FETCH_STRIKETHROUGH_DATA = gql`
  query STRIKETHROUGH {
    lookup {
      strikethroughData {
        externalId
        name
        strikethroughEnabled
        strikeThroughAddons {
          strikeThroughValue
          cabinCategory {
            seawareId
          }
        }
      }
    }
  }
`;

export type GQLStrikethroughAddon = {
  cabinCategory: TNullable<{
    seawareId: string;
  }>;
  strikeThroughValue: TNullable<string>;
};

export type GQLStrikethrough = {
  externalId: TNullable<string>;
  name: TNullable<string>;
  strikeThroughAddons: TNullable<GQLStrikethroughAddon>;
  strikethroughEnabled: TNullable<boolean>;
};

export type GQLStrikethroughApiAnswer = { lookup: { strikethroughData: GQLStrikethrough[] } };
