import HtmlContent from '@/components/elements/HtmlContent';
import { useFrenchWordsWrapper } from '@/hooks/useWrapForFrenchWords';

type Props = {
  className?: string;
  id?: string;
  node?: keyof React.ReactHTML;
  text: string;
};

const WithFrenchContent = ({ className, id, node = 'span', text }: Props) => {
  const html = useFrenchWordsWrapper(text);
  return <HtmlContent className={className} content={html} id={id} node={node} />;
};

export default WithFrenchContent;
