/* eslint-disable perfectionist/sort-object-types */
import { gql } from '@apollo/client';

import type { TImage, TNullable, TNullableRecord } from '@/infra/types/common';

export const FETCH_PROMO_BANNERS_V2 = gql`
  fragment extBillboard on BillboardBanner {
    # {main}
    title: titleText
    description: descriptionText
    # {/main}
    # {eyebrow}
    countdown
    eyebrowStyle
    eyebrowTagColorTheme
    eyebrowTagText
    eyebrowText
    # {/eyebrow}
    # {cta}
    ctaStyle
    ctaTarget
    ctaText
    # {/cta}
    appearance: style
    overlayColor
    contentAlign: contentAlignment
    theme: titleTextColor
    image375 {
      alt
      src
    }
    image768 {
      alt
      src
    }
    image992 {
      alt
      src
    }
    image1200 {
      alt
      src
    }
    image1600 {
      alt
      src
    }
  }

  fragment extDouble on DoubleBanner {
    overlayColor
    appearance: style
    titleBannerOne: bannerTitleTextBannerOne
    titleBannerTwo: bannerTitleTextBannerTwo
    contentAlignBannerOne: contentAlignmentBannerOne
    contentAlignBannerTwo: contentAlignmentBannerTwo
    ctaTargetBannerOne
    ctaTargetBannerTwo
    image375BannerTwo {
      alt
      src
    }
    image768BannerTwo {
      alt
      src
    }
    image992BannerTwo {
      alt
      src
    }
    image1200BannerTwo {
      alt
      src
    }
    image1600BannerTwo {
      alt
      src
    }
    image375BannerOne {
      alt
      src
    }
    image1600BannerOne {
      alt
      src
    }
    image1200BannerOne {
      alt
      src
    }
    image992BannerOne {
      alt
      src
    }
    image768BannerOne {
      alt
      src
    }
  }

  fragment extFeaturette on FeaturetteBanner {
    # {main}
    title: titleText
    description: descriptionText
    # {/main}
    # {eyebrow}
    countdown
    eyebrowStyle
    eyebrowTagColorTheme
    eyebrowTagText
    eyebrowText
    # {/eyebrow}
    # {cta}
    ctaStyle
    ctaTarget
    ctaText
    # {/cta}
    appearance: style
    overlayColor
    contentAlign: contentAlignment
    theme: titleTextColor
    image375 {
      alt
      src
    }
    image768 {
      alt
      src
    }
    image992 {
      alt
      src
    }
    image1200 {
      alt
      src
    }
    image1600 {
      alt
      src
    }
    video375 {
      alt
      src
    }
    video768 {
      alt
      src
    }
    video992 {
      alt
      src
    }
    video1200 {
      alt
      src
    }
    video1600 {
      alt
      src
    }
  }

  fragment extLeaderboard on LeaderboardBanner {
    # {main}
    title: titleText
    description: descriptionText
    # {/main}
    # {cta}
    ctaStyle
    ctaTarget
    ctaText
    # {/cta}
    appearance: style
    overlayColor
    contentAlign: contentAlignment
    # leaderboardPosition
    theme: titleTextColor
    image375 {
      alt
      src
    }
    image768 {
      alt
      src
    }
    image992 {
      alt
      src
    }
    image1200 {
      alt
      src
    }
    image1600 {
      alt
      src
    }
  }

  fragment extMarquee on MarqueeBanner {
    # {main}
    title: titleText
    description: descriptionText
    # {/main}
    # {eyebrow}
    countdown
    eyebrowStyle
    eyebrowTagColorTheme
    eyebrowTagText
    eyebrowText
    emojiField
    # {/eyebrow}
    url: ctaTarget
  }

  fragment extMerchant on MerchandisingBanner {
    # {/eyebrow}
    countdown
    # {/eyebrow}
    # {/perks}
    perksSubheading
    perksPriority
    perks
    # {/perks}
    voyageStart
    voyageEnd
    countdownClockToggle
    # {/promotionAddons}
    promotionAddons {
      name
      voyageLengthRestriction
      min
      max
    }
    # {/promotionAddons}
    sailingsToBeExcluded
    bookingHeaderBackgroundToggle
    voyageCardMerchandising
    voyageCardBarTab
    rotundaToggle
    sailingItemToggle
    barTabHeroImageToggle
    exitInterstitial
    quickSearch
    preCheckoutModuleToggle
    # {/preCheckoutLineItems}
    preCheckoutLineItems {
      id
      name
      path
      bulletItemIcon {
        src
        alt
      }
      bulletItemText
    }
    # {/preCheckoutLineItems}
    colorSwatch1
    colorSwatch2
    colorSwatch3
    colorSwatch4
    colorSwatch5
    colorSwatch6
  }

  query PROMO_BANNERS_V2($countryCode: String) {
    promoBannersV2(countryCode: $countryCode) {
      # name
      # externalId
      # priority
      type: bannerType
      placeId: bannerPosition
      # perksSubheading
      # learnMoreTooltip
      # perksPriority
      # perks
      # countdown
      __fetchedAt @client
      ...extBillboard
      ...extDouble
      ...extFeaturette
      ...extLeaderboard
      ...extMarquee
      ...extMerchant
    }
  }
`;

export type TNullableImage = TNullableRecord<TImage>;

export type GQLMain = {
  title: TNullable<string>;
  description: TNullable<string>;
};

export type GQLEyebrow = {
  countdown: TNullable<string>;
  eyebrowStyle: TNullable<string>;
  eyebrowTagColorTheme: TNullable<string>;
  eyebrowTagText: TNullable<string>;
  eyebrowText: TNullable<string>;
  emojiField: TNullable<string>;
};

export type GQLCta = {
  ctaStyle: TNullable<string>;
  ctaTarget: TNullable<string>;
  ctaText: TNullable<string>;
};

export type GQLPerks = {
  perksSubheading: TNullable<string>;
  perksPriority: TNullable<string>;
  perks: TNullable<string>;
};

export type GQLCampaignsPromoBanners = {
  // name: TNullable<string>;
  // externalId: TNullable<string>;
  // priority: TNullable<string>;
  type: TNullable<string>;
  placeId: TNullable<string>;
  // perksSubheading: TNullable<string>;
  // learnMoreTooltip: TNullable<string>;
  // perksPriority: TNullable<string>;
  // perks: TNullable<string>;
  // countdown: TNullable<string>;
};

export type GQLPromotionAddons = {
  name: TNullable<string>;
  voyageLengthRestriction: TNullable<string>;
  min: TNullable<unknown>;
  max: TNullable<unknown>;
};
export type GQLPreCheckoutLineItems = {
  id: TNullable<string>;
  name: TNullable<string>;
  path: TNullable<string>;
  bulletItemIcon: TNullableRecord<TImage>;
  bulletItemText: TNullable<string>;
};

export type GQLBillboardBanner = {
  appearance: TNullable<string>;
  overlayColor: TNullable<string>;
  contentAlign: TNullable<string>;
  theme: TNullable<string>;
  image375: TNullableImage;
  image768: TNullableImage;
  image992: TNullableImage;
  image1200: TNullableImage;
  image1600: TNullableImage;
} & GQLCampaignsPromoBanners &
  GQLCta &
  GQLEyebrow &
  GQLMain;

export type GQLDoubleBanner = {
  overlayColor: TNullable<string>;
  appearance: TNullable<string>;
  titleBannerOne: TNullable<string>;
  titleBannerTwo: TNullable<string>;
  contentAlignBannerOne: TNullable<string>;
  contentAlignBannerTwo: TNullable<string>;
  ctaTargetBannerOne: TNullable<string>;
  ctaTargetBannerTwo: TNullable<string>;
  image375BannerTwo: TNullableImage;
  image768BannerTwo: TNullableImage;
  image992BannerTwo: TNullableImage;
  image1200BannerTwo: TNullableImage;
  image1600BannerTwo: TNullableImage;
  image375BannerOne: TNullableImage;
  image1600BannerOne: TNullableImage;
  image1200BannerOne: TNullableImage;
  image992BannerOne: TNullableImage;
  image768BannerOne: TNullableImage;
} & GQLCampaignsPromoBanners;

export type GQLFeaturetteBanner = {
  appearance: TNullable<string>;
  overlayColor: TNullable<string>;
  contentAlign: TNullable<string>;
  theme: TNullable<string>;
  image375: TNullableImage;
  image768: TNullableImage;
  image992: TNullableImage;
  image1200: TNullableImage;
  image1600: TNullableImage;
  video375: TNullableImage;
  video768: TNullableImage;
  video992: TNullableImage;
  video1200: TNullableImage;
  video1600: TNullableImage;
} & GQLCampaignsPromoBanners &
  GQLCta &
  GQLEyebrow &
  GQLMain;

export type GQLLeaderboardBanner = {
  appearance: TNullable<string>;
  overlayColor: TNullable<string>;
  contentAlign: TNullable<string>;
  // leaderboardPosition: TNullable<string>;
  theme: TNullable<string>;
  image375: TNullableImage;
  image768: TNullableImage;
  image992: TNullableImage;
  image1200: TNullableImage;
  image1600: TNullableImage;
} & GQLCampaignsPromoBanners &
  GQLCta &
  GQLMain;

export type GQLMarqueeBanner = {
  url: TNullable<string>;
} & GQLCampaignsPromoBanners &
  GQLEyebrow &
  GQLMain;

export type GQLMerchandisingBanner = GQLCampaignsPromoBanners &
  Pick<GQLEyebrow, 'countdown'> &
  GQLPromotionAddons &
  GQLPreCheckoutLineItems &
  GQLPerks & {
    voyageStart: TNullable<string>;
    voyageEnd: TNullable<string>;
    countdownClockToggle: TNullable<boolean>;
    sailingsToBeExcluded: TNullable<string>;
    bookingHeaderBackgroundToggle: TNullable<string>;
    voyageCardMerchandising: TNullable<string>;
    voyageCardBarTab: TNullable<string>;
    rotundaToggle: TNullable<string>;
    sailingItemToggle: TNullable<string>;
    barTabHeroImageToggle: TNullable<string>;
    exitInterstitial: TNullable<string>;
    quickSearch: TNullable<string>;
    preCheckoutModuleToggle: TNullable<string>;
    colorSwatch1: TNullable<string>;
    colorSwatch2: TNullable<string>;
    colorSwatch3: TNullable<string>;
    colorSwatch4: TNullable<string>;
    colorSwatch5: TNullable<string>;
    colorSwatch6: TNullable<string>;
  };

export type GQLPromoBannersApiAnswer = { promoBannersV2: GQLCampaignsPromoBanners[] };
