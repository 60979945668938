import { gql } from '@apollo/client';

export const GET_SPECIAL_SAILINGS = gql`
  query SPECIAL_SAILINGS {
    lookup {
      specialSailings {
        id
        name
        subtitle
        description
        modalDescription
        showRibbon
        priority
        ribbonColorOverride
        portraitCarousel {
          alt
          src
        }
        mobileModalHero {
          alt
          src
        }
      }
    }
  }
`;
