/* eslint-disable perfectionist/sort-objects */
import { type MutableRefObject, useEffect } from 'react';

import type { TBoxSide, TOptional, TSideAlign } from '@/types/common';

import getOppositeSide from '@/helpers/getOppositeSide';
import getVisibleArea, { type TRect } from '@/helpers/getVisibleArea';

export type TCoordinate<V> = { x: V; y: V };
export type TRealign = {
  align: TSideAlign;
  side: TBoxSide;
};
export type TOnRealign = (realign?: TOptional<TRealign>) => void;

type TMetric = { offset: 'offsetLeft' | 'offsetTop'; size: 'offsetHeight' | 'offsetWidth' };

const METRICS: TCoordinate<TMetric> = {
  x: { offset: 'offsetLeft', size: 'offsetWidth' },
  y: { offset: 'offsetTop', size: 'offsetHeight' },
} as const;

export const calcAligned = (
  anchor: HTMLElement,
  target: HTMLElement,
  align: TOptional<TSideAlign>,
  metric: TMetric,
): number => {
  switch (align) {
    case 'end':
      return anchor[metric.offset] + anchor[metric.size] - target[metric.size];
    case 'center':
      return (anchor[metric.offset] + anchor[metric.offset] + anchor[metric.size] - target[metric.size]) / 2;
    case 'start':
    default:
      return anchor[metric.offset];
  }
};

type TCalcPosition = { sideSpace: number } & TCoordinate<number>;

export const calcPosition = (
  anchor: HTMLElement,
  target: HTMLElement,
  side: TBoxSide,
  align: TSideAlign,
  offsetOrigin: TCoordinate<number>,
  visibleArea: TRect,
): TCalcPosition => {
  switch (side) {
    case 'left': {
      const x = anchor.offsetLeft - target.offsetWidth;
      return { x, y: calcAligned(anchor, target, align, METRICS.y), sideSpace: offsetOrigin.x - visibleArea.x + x };
    }
    case 'right': {
      const x = anchor.offsetLeft + anchor.offsetWidth;
      const sideSpace = visibleArea.width - offsetOrigin.x - x - target.offsetWidth;
      return { x, y: calcAligned(anchor, target, align, METRICS.y), sideSpace };
    }
    case 'top': {
      const y = anchor.offsetTop - target.offsetHeight;
      return { x: calcAligned(anchor, target, align, METRICS.x), y, sideSpace: offsetOrigin.y - visibleArea.y + y };
    }
    case 'bottom': {
      const y = anchor.offsetTop + anchor.offsetHeight;
      const sideSpace = visibleArea.height - offsetOrigin.y - y - target.offsetHeight;
      return { x: calcAligned(anchor, target, align, METRICS.x), y, sideSpace };
    }
  }
};

type TPlacement = {
  position: TCoordinate<number>;
  realign?: TOptional<{
    align: TSideAlign;
    side: TBoxSide;
  }>;
};

export const findPlacement = (
  anchor: HTMLElement,
  target: HTMLElement,
  side: TBoxSide,
  align: TSideAlign,
): TPlacement => {
  const offsetOrigin = getOffsetOrigin(target);
  const visibleArea = getVisibleArea();
  const directPosition = calcPosition(anchor, target, side, align, offsetOrigin, visibleArea);
  if (directPosition.sideSpace >= 0) return { position: directPosition };
  const oppositeSide = getOppositeSide(side)!;
  const oppositePosition = calcPosition(anchor, target, oppositeSide, align, offsetOrigin, visibleArea);
  if (oppositePosition.sideSpace < directPosition.sideSpace) return { position: directPosition };
  return { position: oppositePosition, realign: { side: oppositeSide, align } };
};

export const getOffsetOrigin = (node: HTMLElement): TCoordinate<number> => {
  const { x = 0, y = 0 } = node?.offsetParent?.getBoundingClientRect() || {};
  return { x, y };
};

type TArgs<A extends HTMLElement, T extends HTMLElement> = {
  align?: TOptional<TSideAlign>;
  anchorRef: MutableRefObject<A | null>;
  cssVarNames?: TOptional<TCoordinate<string>>;
  disabled?: TOptional<boolean>;
  onRealign?: TOptional<TOnRealign>;
  side?: TOptional<TBoxSide>;
  targetRef: MutableRefObject<T | null>;
};

const usePositionVars = <A extends HTMLElement, T extends HTMLElement>({
  align,
  anchorRef,
  cssVarNames,
  disabled,
  onRealign,
  side,
  targetRef,
}: TArgs<T, A>): void => {
  useEffect(() => {
    const updateCssVars = () => {
      const { current: target } = targetRef || {};
      const { current: anchor } = anchorRef || {};
      if (target && anchor && !disabled) {
        const { position, realign } = findPlacement(anchor, target, side || 'bottom', align || 'center');
        const style = getComputedStyle(target);
        (['x', 'y'] as const).forEach((coor: 'x' | 'y') => {
          const name = `--${cssVarNames?.[coor] || coor}`;
          const value = `${position?.[coor] || 0}px`;
          if (style.getPropertyValue(name) !== value) {
            target.style.setProperty(name, value);
          }
        });
        onRealign?.(realign);
      }
    };
    updateCssVars();
    window.addEventListener('resize', updateCssVars);
    return () => {
      window.removeEventListener('resize', updateCssVars);
    };
  }, [align, anchorRef?.current, cssVarNames, disabled, side, targetRef?.current]);
};

export default usePositionVars;
