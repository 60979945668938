import { gql } from '@apollo/client';

export const GET_BLACK_FRIDAY_PROMOTIONS = gql`
  query BLACK_FRIDAY_PROMOTIONS {
    lookup {
      promotionBanner {
        id
        title
        name
        headLine
        description
        bannerEyebrow
        bannerMainPromoBenefit
        bannerValueProp
        bannerShareLink
        backgroundColor
        tabletBannerImage {
          src
          alt
        }
        flyoutHeroImage {
          src
          alt
        }
        whatsIncluded {
          text
          description
          navigationOrder
          image {
            src
            alt
          }
          icon {
            src
            alt
          }
        }
        mobileBannerImage {
          src
          alt
        }
        bannerImage {
          src
          alt
        }
        sailingsToBeExcluded
        colorSwatch1
        colorSwatch2
        colorSwatch3
        colorSwatch4
        colorSwatch5
        colorSwatch6
        voyageStart
        voyageEnd
        countdownClockToggle
        countdown
        promoBannerVariant
        promotionAddons {
          name
          min
          max
        }
      }
    }
  }
`;
