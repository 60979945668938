import cn from 'classnames';

import type { TPortMessage } from '@/infra/types/voyageInfo/port';

import UIResource from '@/components/UIResource';

import FlyoutPortMessage from './FlyoutPortMessage';
import PrecheckoutPortMessage from './PrecheckoutPortMessage';

import './PortMessage.scss';

export enum PORT_MESSAGE_FORMATS {
  DETAILS_MODULE = 'details-module',
  FLYOUT = 'flyout',
  UNDER_PACKAGE_CARD = 'under-package-card',
}

const getPortMessage = (format: PORT_MESSAGE_FORMATS, swappedPort: TPortMessage = {}) => {
  switch (format) {
    case PORT_MESSAGE_FORMATS.FLYOUT:
      return <FlyoutPortMessage />;
    case PORT_MESSAGE_FORMATS.DETAILS_MODULE:
      return <PrecheckoutPortMessage values={swappedPort} />;
    case PORT_MESSAGE_FORMATS.UNDER_PACKAGE_CARD:
      return <UIResource id="Port change – Voyage card banner" />;
    default:
      return null;
  }
};

type Props = {
  className?: string;
  format: PORT_MESSAGE_FORMATS;
  swappedPort?: TPortMessage;
};

const PortMessage = ({ className, format, swappedPort }: Props) => (
  <div className={cn(className, 'PortChangeMessageSection', format)}>
    <span className="portText">{getPortMessage(format, swappedPort)}</span>
  </div>
);

export default PortMessage;
