/* eslint-disable perfectionist/sort-objects, perfectionist/sort-object-types */

export const measurePageHeaderHeight = () => {
  // TODO: Implement! Replace the fixed height of the header with the actual measured current height
  return 100;
};

export type TRect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

const getVisibleArea = (): TRect => {
  const offsetTop = measurePageHeaderHeight();
  const { clientHeight, clientWidth } = document.documentElement;
  return { x: 0, y: offsetTop, width: clientWidth, height: clientHeight - offsetTop };
};

export default getVisibleArea;
