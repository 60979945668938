import UIResource from '@/components/UIResource';

const FlyoutPortMessage = () => (
  <>
    <span className="portHeading">
      <UIResource id="Itinerary flyout – Note to sailors heading" />
    </span>
    <span className="body">
      <UIResource id="Itinerary flyout – Note to sailors body" />
    </span>
  </>
);

export default FlyoutPortMessage;
